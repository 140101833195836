@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;500&display=swap");


:root {
	--primary-color: #faca2e;
	--eye-pupil-color: #050505;
	--bg-color: #fff;
	--text-color: #000;
	--fs-heading: 36px;
	--fs-text: 26px;
	--fs-button: 18px;
	--fs-icon: 30px;
	--pupil-size: 30px;
	--eye-size: 80px;
	--button-padding: 15px 30px;

	@media only screen and (max-width: 567px) {
		--fs-heading: 30px;
		--fs-text: 22px;
		--fs-button: 16px;
		--fs-icon: 24px;
		--button-padding: 12px 24px;
	}
}

.container-notfound {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 30px;
	text-align: center;
}

.error-page {
	margin: auto;

	&__heading {
		&-title {
			text-transform: capitalize;
			font-size: var(--fs-heading);
			font-weight: 500;
			color: var(--primary-color);
		}

		&-desciption {
			margin-top: 10px;
			font-size: var(--fs-text);
			font-weight: 200;
		}
	}

	&__button {
		color: inherit;
		text-decoration: none;
		border: 1px solid var(--primary-color);
		font-size: var(--fs-button);
		font-weight: 200;
		padding: var(--button-padding);
		border-radius: 15px;
		box-shadow: 0px 7px 0px -2px var(--primary-color);
		transition: all 0.3s ease-in-out;
		text-transform: capitalize;

		&:hover {
			box-shadow: none;
			background-color: var(--primary-color);
			color: #fff;
		}
	}
}

