.user-chat-profile {
    border-radius: 10px;
    background-color: #f8f9fa;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.user-chat-profile:hover {
    background-color: #e2e6ea;
}

.profile-picture-container {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
}

.profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.captain-badge {
    font-size: 0.6rem;
    padding: 0.2rem 0.4rem;
    border-radius: 50%;
    margin-left: 5px;
}

.last-message {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.last-active-time {
    font-size: 0.8rem;
}
