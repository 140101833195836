.notification-item {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    transition: background-color 0.3s;
}

.notification-item.unseen {
    background-color: #f8f9fa;
}

.notification-item:hover {
    background-color: #e9ecef;
    cursor: pointer;
}

.notification-content {
    display: flex;
    align-items: center;
    width: 80%;
}

.notification-icon {
    margin-right: 15px;
}

.notification-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.notification-text {
    flex: 1;
}

.notification-type {
    margin-bottom: 5px;
    font-size: 16px;
    color: #343a40;
}

.notification-message {
    margin: 0;
    font-size: 14px;
    color: #6c757d;
}

.notification-actions {
    margin-top: 10px;
}

.notification-time {
    text-align: right;
    font-size: 12px;
    color: #adb5bd;
}
