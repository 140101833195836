.loader {
  position: relative;
  /* width: 12rem; */
  /* height: 12rem; */
  min-width: 8rem;
  min-height: 8rem;
  background-color: #fff;
  }
  
  .ball,
  .bat {
    position: absolute;
  }
  
  .ball {
    width:8%;
    height: 8%;
    left: 0.5rem;
    top: 3rem;
    z-index: 2;
    animation: bounceBall 1s infinite;
    animation-delay: 1s;
  }
  
  .bat {
    width: 50%;
    top: 0;
    left: 0;
    z-index: 1;
    transform: rotateX(-40deg);
    animation: moveBat 1s infinite;
  }
  
  @keyframes bounceBall {
    from,
    20%,
    53%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0);
    }
  
    40%,
    43% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -60px, 0);
    }
  
    70% {
      transform: translate3d(0, -30px, 0);
    }
  
    90% {
      transform: translate3d(0, -8px, 0);
    }
  }
  
  @keyframes moveBat {
    0% {
      transform: rotateX(0deg);
    }
  
    33% {
      transform: rotateX(-25deg);
    }
  
    100% {
      transform: rotateX(0deg);
    }
  }
  